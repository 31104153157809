<template>
  <div class="wrap">
    <div class="bigBlock">
      <div class="iframeWrap" :class="dualScreenInfo == 1 ? 'w-1292' : ''">
        <div v-show="currentPage == 0" class="faceImgBox">
          <img class="faceImg" :src="bookDetails ? bookDetails.faceImg : ''" />
        </div>
        <div class="rTag blue top-200" @click="contentChange">
          <div class="symbol iconfont icon-mulu"></div>
          <div class="tips">章节目录</div>
        </div>
        <div class="rTag brown top-264" @click="goBookDetail">
          <div class="symbol iconfont icon-jiaocaixiangqing"></div>
          <div class="tips">教材详情</div>
        </div>
        <div class="rTag green top-328" @click="goCourseDetail">
          <div class="symbol iconfont icon-kechengxiangqing"></div>
          <div class="tips">课程详情</div>
        </div>
        <div class="lTag" v-if="!IsPurchase" @click="zhifu">购买套餐</div>
        <!-- <div class="fullScreen iconfont icon-quanping1"></div> -->
        <div class="dualScreen iconfont icon-shuangping" @click="dualScreen"></div>
        <div class="leftArrow" @click="debounce(nextPage, 500)">
          <div class="iconfont icon-xiangyou"></div>
        </div>
        <div class="rightArrow" @click="debounce(prePage, 500)">
          <div class="iconfont icon-xiangzuo"></div>
        </div>
        <div v-if="currentPage > 0" :class="dualScreenInfo == 1 ? 'pdfBox' : ''">
          <pdf ref="pdf" @progress="progress" @num-pages="total = $event" @loaded="loadPdfHandler" :page="currentPage"
            class="pdf-item" :src="bookDetails ? bookDetails.netFilePath : ''"></pdf>
          <pdf v-if="dualScreenInfo == 1" ref="pdf" @progress="progress" @num-pages="total = $event"
            :page="currentPage + 1" class="pdf-item" :src="bookDetails ? bookDetails.netFilePath : ''"></pdf>
        </div>
        <div class="coverBox" v-if="!IsPurchase && coverInfo">
          <div class="tipsWrap">
            <div class="tipsBlock">
              <i class="iconfont icon-suotou"></i>购买课程解锁全部教材
            </div>
            <div class="tipsBtn" @click="zhifu">立即购买</div>
          </div>
        </div>
        <div class="contentWrap" v-show="contentInfo == 1" @click="contentChange">
          <div class="contentBox" v-html="ImgSizeLimit(bookDetails ? bookDetails.chapterText : '')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/api/bus";
import Vue from "vue";
import pdf from "vue-pdf";
import { getCurSelfResearchTextbook } from "@/api/home.js";
import { getInfo, getToken } from "@/api/cookies";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      IsPurchase: null, // 是否购买
      bookDetails: null, // 教材详情
      classesId: null, // 所属套餐ID
      id: null, // 教材ID
      title: null, // 教材名字
      coverInfo: false,
      className: null, // 所属套餐

      currentPage: 0, // 当前页
      loadedRatio: 1, // 当前页加载进度
      total: 8, // 总页数
      dualScreenInfo: 0, // 是否双排 1 是
      contentInfo: false, // 目录是否显示
      tryRead: null, // 限定试看页数
    };
  },
  async created() {
    this.className = this.$route.query.title;
    this.id = await this.$route.query.id;
    this.IsPurchase = eval(this.$route.query.IsPurchase);
    this.classesId = this.$route.query.classesId;
    this.getCurSelfResearchTextbook();
  },
  mounted() { },
  watch: {
    currentPage() {
      if (this.currentPage == this.tryRead || this.currentPage > this.tryRead) {
        this.coverInfo = true;
      } else {
        this.coverInfo = false;
      }
    },
  },
  methods: {
    // 点击立即报名按钮 跳转支付页
    zhifu() {
      let token = getToken();
      if (token) {
        this.$router.push(`/orders/classOrder?classId=${this.classesId}`);
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    /* 教材详情 */
    getCurSelfResearchTextbook() {
      getCurSelfResearchTextbook(this.id).then((res) => {
        this.bookDetails = res.msg;
        this.tryRead = res.msg.tryRead;
      });
    },
    /* 点击目录 */
    contentChange() {
      this.contentInfo = !this.contentInfo;
    },
    /* 点击教材详情 */
    goBookDetail() {
      this.$router.push({
        path: "/typeclassxq/textbookDetails",
        query: {
          id: this.id,
          title: this.className,
          classesId: this.classesId,
          IsPurchase: this.IsPurchase,
        },
      });
    },
    /* 点击课程详情 */
    goCourseDetail() {
      this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: this.classesId,
        },
      });
    },
    /* 点击双屏 */
    dualScreen() {
      this.dualScreenInfo =
        this.dualScreenInfo == 1
          ? (this.dualScreenInfo = 0)
          : (this.dualScreenInfo = 1);
    },
    // pdf加载时
    loadPdfHandler(e) {
      // 加载的时候先加载第一页
      this.currentPage = 1;
    },
    /* 防抖 */
    debounce(fn, time) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fn, time);
    },
    // 上一页
    prePage() {
      if (this.IsPurchase) {
        let page = this.currentPage;
        page = page > 1 ? page - 1 : this.total;
        this.currentPage = page;
      } else if (
        !this.IsPurchase &&
        this.currentPage < this.tryRead + 1 &&
        this.currentPage > 1
      ) {
        let page = this.currentPage;
        page = page > 1 ? page - 1 : this.total;
        this.currentPage = page;
      } else {
        this.currentPage = this.tryRead;
      }
    },
    // 下一页
    nextPage() {
      if (this.IsPurchase) {
        let page = this.currentPage;
        page = page < this.total ? page + 1 : 1;
        this.currentPage = page;
      } else if (!this.IsPurchase && this.currentPage < this.tryRead) {
        let page = this.currentPage;
        page = page < this.total ? page + 1 : 1;
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
    },
    // 加载
    progress(type) {
      this.loadedRatio = type;
      if (type == 1) {
        this.loading.close();
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "加载中",
          spinner: "",
          background: "rgba(0, 0, 0, 0.1)",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  .bigBlock {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: #282b33;

    .w-1292 {
      width: 1292px !important;
    }

    .iframeWrap {
      position: relative;
      width: 646px;
      min-height: 100vh;
      background-color: white;
      margin: auto;

      .faceImgBox {
        width: 280px;
        height: 360px;
        position: absolute;
        top: 50%;
        margin-top: -180px;
        left: 50%;
        margin-left: -140px;
        background-color: #f5f7f9;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .faceImg {
          width: 240px;
          height: 320px;
        }
      }

      .pdfBox {
        display: flex;

        .pdf-item {
          width: 50%;
        }
      }

      .coverBox {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .tipsWrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .tipsBlock {
            width: 290px;
            height: 74px;
            background-color: rgba(0, 0, 0, 0.8);
            color: #ffffff;
            font-size: 18px;
            border-radius: 8px;
            text-align: center;
            line-height: 74px;
            margin-bottom: 32px;

            .icon-suotou {
              margin-right: 12px;
            }
          }

          .tipsBtn {
            width: 120px;
            height: 40px;
            background-color: #ff5e51;
            color: #ffffff;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }
        }
      }

      .rTag {
        cursor: pointer;
        position: absolute;
        left: -60px;
        width: 60px;
        height: 44px;
        border-radius: 24px 0 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;

        .symbol {
          position: absolute;
          left: 20px;
        }

        .tips {
          position: absolute;
          left: 45px;
        }
      }

      .lTag {
        position: absolute;
        top: 130px;
        right: -120px;
        width: 120px;
        height: 44px;
        border-radius: 0 24px 24px 0;
        background-color: #b23535;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
        cursor: pointer;
      }

      .top-200 {
        top: 200px;
      }

      .top-264 {
        top: 264px;
      }

      .top-328 {
        top: 328px;
      }

      .blue {
        background-color: #356fb2;
      }

      .blue:hover {
        width: 120px !important;
        left: -120px !important;
        transition: 300ms;
      }

      .brown {
        background-color: #b27135;
      }

      .brown:hover {
        width: 120px !important;
        left: -120px !important;
        transition: 300ms;
      }

      .green {
        background-color: #308e61;
      }

      .green:hover {
        width: 120px !important;
        left: -120px !important;
        transition: 300ms;
      }

      .fullScreen {
        width: 40px;
        height: 40px;
        border: 1px solid #666e7f;
        position: absolute;
        top: 334px;
        right: -80px;
        text-align: center;
        line-height: 40px;
        color: #666e7f;
        font-size: 16px;
      }

      .dualScreen {
        width: 40px;
        height: 40px;
        border: 1px solid #666e7f;
        position: absolute;
        top: 373px;
        right: -80px;
        text-align: center;
        line-height: 40px;
        color: #666e7f;
        font-size: 16px;
        cursor: pointer;
      }

      .leftArrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 533px;
        right: -80px;
        color: #666e7f;
        text-align: center;
        line-height: 80px;
        border: 1px solid #666e7f;
        cursor: pointer;
      }

      .rightArrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 612px;
        right: -80px;
        color: #666e7f;
        text-align: center;
        line-height: 80px;
        border: 1px solid #666e7f;
        cursor: pointer;
      }

      .contentWrap {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .contentBox {
          width: 566px;
          height: 760px;
          background-color: #ffffff;
          padding: 20px;
          overflow: auto;
        }
      }
    }
  }
}
</style>
